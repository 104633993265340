import { Languages } from "../models/Languages";
import { Language } from "../models/Language";
import { LanguagePayload } from "../models/LanguagePayload";
import { PictureResponse } from "../models/PictureResponse";
import { baseUrl, headers } from "./config";
import { appendFormData, fetchAndParse } from "./utils";
export function getApiLanguageList(): Promise<Languages> {
    const url = new URL(`/api/language/list`, baseUrl);
    return fetchAndParse<Languages>(url.toString(), { method: "GET", headers });
}
export function getApiLanguageLanguageid(languageId: number): Promise<Language> {
    const url = new URL(`/api/language/${languageId}`, baseUrl);
    return fetchAndParse<Language>(url.toString(), { method: "GET", headers });
}
export function putApiLanguageLanguageid(languageId: number, body?: LanguagePayload): Promise<Language> {
    const url = new URL(`/api/language/${languageId}`, baseUrl);
    return fetchAndParse<Language>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiLanguageLanguageid(languageId: number): Promise<boolean> {
    const url = new URL(`/api/language/${languageId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiLanguage(body?: LanguagePayload): Promise<Language> {
    const url = new URL(`/api/language`, baseUrl);
    return fetchAndParse<Language>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function putApiLanguageLanguageidPicture(languageId: number, picture: File): Promise<PictureResponse> {
    const localHeaders = { ...headers };
    delete (localHeaders as any)["Content-Type"];
    const data = new FormData();
    appendFormData(data, "picture", picture);
    const url = new URL(`/api/language/${languageId}/picture`, baseUrl);
    return fetchAndParse<PictureResponse>(url.toString(), { method: "PUT", body: data, headers: localHeaders });
}
