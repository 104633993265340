import { Segments } from "../models/Segments";
import { Segment } from "../models/Segment";
import { SegmentPayload } from "../models/SegmentPayload";
import { SegmentSearch } from "../models/SegmentSearch";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiSegmentList(): Promise<Segments> {
    const url = new URL(`/api/segment/list`, baseUrl);
    return fetchAndParse<Segments>(url.toString(), { method: "GET", headers });
}
export function getApiSegmentSegmentid(segmentId: number): Promise<Segment> {
    const url = new URL(`/api/segment/${segmentId}`, baseUrl);
    return fetchAndParse<Segment>(url.toString(), { method: "GET", headers });
}
export function putApiSegmentSegmentid(segmentId: number, body?: SegmentPayload): Promise<Segment> {
    const url = new URL(`/api/segment/${segmentId}`, baseUrl);
    return fetchAndParse<Segment>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiSegmentSegmentid(segmentId: number): Promise<boolean> {
    const url = new URL(`/api/segment/${segmentId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiSegment(body?: SegmentPayload): Promise<Segment> {
    const url = new URL(`/api/segment`, baseUrl);
    return fetchAndParse<Segment>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiSegmentSearch(body?: SegmentSearch): Promise<Segments> {
    const url = new URL(`/api/segment/search`, baseUrl);
    return fetchAndParse<Segments>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
