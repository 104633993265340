import { Categories } from "../models/Categories";
import { Category } from "../models/Category";
import { CategoryPayload } from "../models/CategoryPayload";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiCategoryList(): Promise<Categories> {
    const url = new URL(`/api/category/list`, baseUrl);
    return fetchAndParse<Categories>(url.toString(), { method: "GET", headers });
}
export function getApiCategoryCategoryid(categoryId: number): Promise<Category> {
    const url = new URL(`/api/category/${categoryId}`, baseUrl);
    return fetchAndParse<Category>(url.toString(), { method: "GET", headers });
}
export function putApiCategoryCategoryid(categoryId: number, body?: CategoryPayload): Promise<Category> {
    const url = new URL(`/api/category/${categoryId}`, baseUrl);
    return fetchAndParse<Category>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiCategoryCategoryid(categoryId: number): Promise<boolean> {
    const url = new URL(`/api/category/${categoryId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiCategory(body?: CategoryPayload): Promise<Category> {
    const url = new URL(`/api/category`, baseUrl);
    return fetchAndParse<Category>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
