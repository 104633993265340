import { Users } from "../models/Users";
import { User } from "../models/User";
import { UpdateUserPayload } from "../models/UpdateUserPayload";
import { NewUserPayload } from "../models/NewUserPayload";
import { UserLogInPayload } from "../models/alt-UserLogInPayload";
import { Model1 } from "../models/alt-Model1";
import { LoginResponse } from "../models/LoginResponse";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiUserList(): Promise<Users> {
    const url = new URL(`/api/user/list`, baseUrl);
    return fetchAndParse<Users>(url.toString(), { method: "GET", headers });
}
export function getApiUserUserid(userId: number): Promise<User> {
    const url = new URL(`/api/user/${userId}`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "GET", headers });
}
export function putApiUserUserid(userId: number, body?: UpdateUserPayload): Promise<User> {
    const url = new URL(`/api/user/${userId}`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiUserUserid(userId: number): Promise<boolean> {
    const url = new URL(`/api/user/${userId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiUser(body?: NewUserPayload): Promise<User> {
    const url = new URL(`/api/user`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiUserLogin(body?: UserLogInPayload | Model1): Promise<LoginResponse> {
    const url = new URL(`/api/user/login`, baseUrl);
    return fetchAndParse<LoginResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
