import { Countries } from "../models/Countries";
import { Country } from "../models/Country";
import { CountryPayload } from "../models/CountryPayload";
import { CountrySearch } from "../models/CountrySearch";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiCountryList(): Promise<Countries> {
    const url = new URL(`/api/country/list`, baseUrl);
    return fetchAndParse<Countries>(url.toString(), { method: "GET", headers });
}
export function getApiCountryCountryid(countryId: number): Promise<Country> {
    const url = new URL(`/api/country/${countryId}`, baseUrl);
    return fetchAndParse<Country>(url.toString(), { method: "GET", headers });
}
export function putApiCountryCountryid(countryId: number, body?: CountryPayload): Promise<Country> {
    const url = new URL(`/api/country/${countryId}`, baseUrl);
    return fetchAndParse<Country>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiCountryCountryid(countryId: number): Promise<boolean> {
    const url = new URL(`/api/country/${countryId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiCountry(body?: CountryPayload): Promise<Country> {
    const url = new URL(`/api/country`, baseUrl);
    return fetchAndParse<Country>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiCountrySearch(body?: CountrySearch): Promise<Countries> {
    const url = new URL(`/api/country/search`, baseUrl);
    return fetchAndParse<Countries>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
