import React, { useState } from "react";
import MaterialTable, { Options } from "material-table";
import { User } from "../models/User";
import { urls, useRouting } from "../utils/routing";
import { useData } from "../hooks/useData";
import { deleteApiUserUserid, getApiUserList } from "../services/user";
import { Users } from "../models/Users";
import { AddCircle } from "@material-ui/icons";
import { Container } from "@material-ui/core";

export const UsersList = () => {
  const { routeTo } = useRouting();
  const [reload, setReload] = useState(false);
  const { data } = useData<Users>(getApiUserList, [reload]);
  const [pagination] = useState<Options<any>>({
    actionsColumnIndex: -1,
    sorting: false,
    pageSize: 10,
    toolbarButtonAlignment: "left",
  });
  if (!data) {
    return <div></div>;
  }
  return (
    <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
      <MaterialTable<User>
        title="Users"
        options={pagination}
        columns={[
          { title: "Email", field: "email" },
          { title: "UID", field: "uid" },
          {
            title: "Language",
            field: "language",
            render: (rowData) => <div>{rowData.language?.name}</div>,
          },
          { title: "Country", field: "country.name", render: (rowData) => <div>{rowData.country?rowData.country.name:''}</div> },
          { title: "Age", field: "age" },
          { title: "IsAdmin", field: "isAdmin" },
        ]}
        data={data?.items!}
        actions={[
          {
            icon: () => <AddCircle />,
            tooltip: "Add New",
            isFreeAction: true,
            onClick: () => routeTo(urls.userAdd),
          },
          {
            icon: "edit",
            tooltip: "Edit this entry",
            isFreeAction: false,
            onClick: (event, data) => {
              if (!Array.isArray(data)) {
                routeTo(urls.userEdit, { id: data.id.toString() });
              }
            },
          },
        ]}
        editable={{
          onRowDelete: async (oldData) => {
           await deleteApiUserUserid(oldData.id);
           setReload(!reload);
          },
        }}
      />
    </Container>
  );
};
