import { Notifications } from "../models/Notifications";
import { Notification } from "../models/Notification";
import { NotificationPayload } from "../models/NotificationPayload";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiNotificationList(): Promise<Notifications> {
    const url = new URL(`/api/notification/list`, baseUrl);
    return fetchAndParse<Notifications>(url.toString(), { method: "GET", headers });
}
export function getApiNotificationNotificationid(notificationId: number): Promise<Notification> {
    const url = new URL(`/api/notification/${notificationId}`, baseUrl);
    return fetchAndParse<Notification>(url.toString(), { method: "GET", headers });
}
export function putApiNotificationNotificationid(notificationId: number, body?: NotificationPayload): Promise<Notification> {
    const url = new URL(`/api/notification/${notificationId}`, baseUrl);
    return fetchAndParse<Notification>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiNotificationNotificationid(notificationId: number): Promise<boolean> {
    const url = new URL(`/api/notification/${notificationId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiNotification(body?: NotificationPayload): Promise<Notification> {
    const url = new URL(`/api/notification`, baseUrl);
    return fetchAndParse<Notification>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
