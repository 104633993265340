import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { localStorageUserKey } from "../services/config";
import { urls } from "./routing";

export function getKeys<T>(p: Record<keyof T, 1>): { [P in keyof T]-?: P } {
    for (const k in p) {
        p[k] = k as any;
    }
    return p as any;
}

export function useTitle(docTitle: string) {
    const [title, setTitle] = useState(docTitle);
    useEffect(() => {
        document.title = title;
    }, [title]);
    return setTitle;
}

export function confirmAndDelete<T extends { id: number; name: string }>(
    e: T,
    ref: React.MutableRefObject<any>,
    fn?: (id: number) => Promise<boolean>
) {
    return async () => {
        let confirmation = window.confirm(
            `Are you sure you want to delete ${e.name}?`
        );
        if (confirmation) {
            await fn!(e.id);
        }
        if (ref.current) {
            ref.current.onQueryChange();
        }
    };
}

export function withPreventDefault(
    fn: (e: React.FormEvent<HTMLFormElement>) => void
) {
    return function (e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        fn(e);
    };
}
export function getCustomFilterAndSearch<T>(getString: (obj: T) => string) {
    return {
        customFilterAndSearch: (term: string, r: T) => {
            return (getString(r).toLocaleLowerCase()).indexOf(term.toLocaleLowerCase()) !== -1
        }, customSort: (a: T, b: T) => {
            return getString(a).localeCompare(getString(b));
        }
    }
}
export function useLoadData(
    fn: () => Promise<void>,
    deps: readonly any[] = []
) {
    const hist = useHistory();
    useEffect(() => {
        fn().catch((e) => {
            if (e.response && e.response.status === 401) {
                window.localStorage.removeItem(localStorageUserKey);
                hist.push(urls.login());
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
