/// Demo for customizing textinputs using withstyles
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, InputBase, InputBaseProps, InputLabel } from '@material-ui/core';
import React from 'react';
const CustomInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
    error: {
        color: "red",
        // color: theme.palette.secondary.main,
        paddingTop: "5px",
        paddingBottom: "5px",
    }
}));

export const CustomTextfield = (p: InputBaseProps & {
    label?: string
    helpertext?: string;
}) => {
    const classes = useStyles();
    const { label, ...rest } = p;
    return <FormControl style={{ width: p.fullWidth ? "100%" : "auto" }}>
        <InputLabel shrink htmlFor="bootstrap-input">
            {label} {rest.required && "*"}
        </InputLabel>
        <CustomInput {...rest} fullWidth />
        {p.error && <span className={classes.error}>{p.helpertext ?? ""}</span>}
    </FormControl>
}