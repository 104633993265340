import { Button, withStyles } from "@material-ui/core";

export const CustomButton = withStyles((theme) => ({
    root: {
        borderRadius: 0,
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(2, 4),
        fontSize: theme.typography.pxToRem(14),
        boxShadow: 'none',
        backgroundColor: "#ff7a7a",
        '&:active, &:focus': {
            boxShadow: 'none',
        },
        '&:disabled': {
            backgroundColor: "grey",
        },
        "&:hover": {
            backgroundColor: "#ff6363",
        }
    },
    sizeSmall: {
        padding: theme.spacing(1, 3),
        fontSize: theme.typography.pxToRem(13),
    },
    sizeLarge: {
        padding: theme.spacing(2, 5),
        fontSize: theme.typography.pxToRem(16),
    },
}))(Button);