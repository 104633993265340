import { toast } from "react-toastify";

export async function fetchAndParse<T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> {
  const response = await fetch(input, init);
  if (response.status >= 400) {
    console.log(response);
    const text = await response.text();
    toast(response.statusText, {
      style: {
        backgroundColor: "#ff8080",
        color: "white",
      },
      type: "success",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // eslint-disable-next-line no-throw-literal
    throw {
      status: response.status,
      statusText: response.statusText,
      text,
      toString() {
        return `${this.status} - ${this.text || this.statusText}`;
      },
    };
  }
  try {
    const data = await response.json();
    return data as T;
  } catch (e) {
    return (response as unknown) as T;
  }
}

export function appendFormData(data: FormData, name: string, file: File) {
  data.append(name, file)
}


