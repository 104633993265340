import { Channels } from "../models/Channels";
import { Channel } from "../models/Channel";
import { ChannelPayload } from "../models/ChannelPayload";
import { ChannelSearch } from "../models/ChannelSearch";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getApiChannelList(): Promise<Channels> {
    const url = new URL(`/api/channel/list`, baseUrl);
    return fetchAndParse<Channels>(url.toString(), { method: "GET", headers });
}
export function getApiChannelChannelid(channelId: number): Promise<Channel> {
    const url = new URL(`/api/channel/${channelId}`, baseUrl);
    return fetchAndParse<Channel>(url.toString(), { method: "GET", headers });
}
export function putApiChannelChannelid(channelId: number, body?: ChannelPayload): Promise<Channel> {
    const url = new URL(`/api/channel/${channelId}`, baseUrl);
    return fetchAndParse<Channel>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiChannelChannelid(channelId: number): Promise<boolean> {
    const url = new URL(`/api/channel/${channelId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiChannel(body?: ChannelPayload): Promise<Channel> {
    const url = new URL(`/api/channel`, baseUrl);
    return fetchAndParse<Channel>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiChannelSearch(body?: ChannelSearch): Promise<Channels> {
    const url = new URL(`/api/channel/search`, baseUrl);
    return fetchAndParse<Channels>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
