import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
export function SingleSelect<
  T extends { name: string; id: number }
>(props: {
  readonly getSuggestions: () => Promise<T[]>;
  autoFocus?: boolean;
  className?: string;
  initalValues?: T;
  value?: T;
  variant?: TextFieldProps["variant"];
  required?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  label: string;
  disabled?: boolean;
  helperText?: string;
  renderOption?: (option: T) => JSX.Element;
  onChange: (value: T | null) => void;
}) {
  const {
    getSuggestions,
    required,
    label,
    onChange,
    autoFocus,
    helperText,
    variant,
    value: values,
    renderOption,
    disableCloseOnSelect,
    disableClearable,
    disabled,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [inital, setInitial] = React.useState<T | null>(
    props.initalValues ?? null
  );
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<T[]>([]);
  // const loading = open;
  React.useEffect(() => {
    let active = true;
    // if (!loading) {
    //   return undefined;
    // }
    (async () => {
      if (active) {
        setLoading(true);
        let result = await getSuggestions();
        setOptions(result);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [getSuggestions]);
  React.useEffect(() => {
    setInitial(values ?? null);
  }, [values]);
  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);  

  return (
    <Autocomplete<T>
      disabled={disabled}
      fullWidth
      disableClearable={(disableClearable ?? false) as any}
      className={props.className}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(change, val) => {
        setInitial(val);
        onChange(val);
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      value={inital}
      getOptionSelected={(option, value) =>
        option.name === value.name || option.id === value.id
      }
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <React.Fragment>
          {renderOption ? renderOption(option) : <span>{option.name}</span>}
        </React.Fragment>
      )}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          helperText={helperText ?? null}
          required={required ?? false}
          autoFocus={autoFocus ?? false}
          label={label}
          variant={variant}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
