import React from "react";
import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CustomTextfield } from "../components/CustomTextfield";
import { CustomButton } from "../components/CustomButton";
import { FormContainer } from "../components/FormContainer";
import FormFeedback from "../components/FormFeedback";
import { Form, Formik } from "formik";
import { getKeys } from "../utils/utilFunctions";
import { urls, useRouting } from "../utils/routing";
import { postApiUserLogin } from "../services/user";
import { headers, localStorageUserKey } from "../services/config";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    // borderRadius: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(6),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));
export type LoginForm = {
  email: string;
  password: string;
};
export const LoginPage = () => {
  const classes = useStyles();
  const { routeTo } = useRouting();
  const [error, setError] = React.useState<string>(null!);
  const initialValues = {
    email: "test@test.com",
    password: "test1234",
  };
  const keys = getKeys<LoginForm>({
    email: 1,
    password: 1,
  });
  const onSubmit = async (o: LoginForm) => {
    try {
      const userLoginData = await postApiUserLogin({ ...o, authType: 0 });
      headers.Authorization = userLoginData.access_token!;
      localStorage.setItem(localStorageUserKey, JSON.stringify(userLoginData));
      routeTo(urls.userList);
    } catch (e) {
      setError(e.statusText);
    }
  };
  return (
    <FormContainer>
      <React.Fragment>
        <Typography variant="h3" gutterBottom align="center">
          Log in
        </Typography>
      </React.Fragment>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, handleChange }) => {
          return (
            <Form className={classes.form}>
              <CustomTextfield
                id={keys.email}
                label="Email"
                fullWidth
                required
                type="email"
                onChange={handleChange}
              />
              <CustomTextfield
                id={keys.password}
                label="Password"
                type="password"
                required
                autoComplete="none"
                fullWidth
                onChange={handleChange}
              />

              {error && (
                <FormFeedback className={classes.feedback} error>
                  {error}
                </FormFeedback>
              )}
              <CustomButton
                className={classes.button}
                disabled={isSubmitting}
                size="large"
                color="secondary"
                variant="contained"
                type="submit"
                fullWidth
              >
                {isSubmitting ? "Loading ..." : "Sign In"}
              </CustomButton>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
