import { Notification } from "../models/Notification"
import MaterialTable, { Options } from "material-table";
import { forwardRef, useState } from "react";
import { useData } from "../hooks/useData";
import { AddCircle } from "@material-ui/icons";
import { Container } from "@material-ui/core";
import { deleteApiNotificationNotificationid, getApiNotificationList, postApiNotification, putApiNotificationNotificationid } from "../services/notification";

export const NotificationsList = () => {
    const [reload, setReload] = useState(false);
    const { data } = useData(getApiNotificationList, [reload]);
    const [pagination] = useState<Options<any>>({
        actionsColumnIndex: -1,
        sorting: false,
        pageSize: 10,
        toolbarButtonAlignment: "left",
    });
    if (!data) {
        return <div></div>;
    }
    return (
        <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
            <MaterialTable<Notification>
                data={data!.items}
                title="Notifications"
                options={pagination}
                icons={{
                    Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
                        <AddCircle {...props} ref={ref} />
                    )),
                }}
                columns={[
                    {
                        title: "Title",
                        field: "title",
                    },
                    {
                        title: "Message",
                        field: "message",
                    },
                ]}
                editable={{
                    onRowAdd: async (data) => {
                        await postApiNotification(data);
                        setReload(!reload);
                    },
                    onRowUpdate: async (newData) => {
                        const { date, id, ...rest } = newData;
                        await putApiNotificationNotificationid(newData.id, rest);
                        setReload(!reload);
                    },
                    onRowDelete: async (oldData) => {
                        await deleteApiNotificationNotificationid(oldData.id);
                        setReload(!reload);
                    },
                }}
            />
        </Container>
    );
};
