import { useState, useEffect } from "react";
import { localStorageUserKey } from "../services/config";
import { urls, useRouting } from "../utils/routing";

export function useData<T>(
  dataLoader: () => Promise<T>,
  deps: readonly any[] = []
) {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const { routeTo } = useRouting();
  useEffectAsync(async () => {
    try {
      setIsLoading(true);
      const newData = await dataLoader();
      console.log(newData);
      setData(newData);
      setError(undefined);
    } catch (e) {
      console.log(e);
      //unauthorized
      if (e.status === 401) {
        localStorage.removeItem(localStorageUserKey);
        routeTo(urls.login);
      }
      if (typeof e === "string") {
        setError(e);
      } else {
        setError("An error occured while getting the data");
      }
    } finally {
      setIsLoading(false);
    }
  }, deps);

  return { data, error, isLoading, setData };
}
export function useEffectAsync(fn: () => Promise<void>, deps?: readonly any[]) {
  useEffect(() => {
    fn().catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
