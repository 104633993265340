import { useEffect, useState } from "react";
import { LoginResponse } from "../models/LoginResponse";
import { localStorageUserKey, headers } from "../services/config";
import { urls, useRouting } from "../utils/routing";
export const useSavedUser = () => {
  const [userChecked, setUserChecked] = useState(false);
  const { routeTo } = useRouting();
  useEffect(() => {
    const user = localStorage.getItem(localStorageUserKey);
    if (user) {
      const parsedUser = JSON.parse(user) as LoginResponse;
      headers.Authorization = parsedUser.access_token!;
      if (window.location.pathname === "/")
        routeTo(urls.userList);
    } else {
      routeTo(urls.login);
    }
    setUserChecked(true);
    // eslint-disable-next-line
  }, []);
  return { userChecked };
};
