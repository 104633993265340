import { Button, LinearProgress, Container, Grid, makeStyles, Paper, TextField, Typography, CircularProgress, Backdrop, FormLabel } from "@material-ui/core"
import { Form, Formik } from "formik"
import React from "react"
import { useState } from "react"
import { VideoPayload } from "../models/VideoPayload"
import { urls, useRouting } from "../utils/routing"
import { postApiSegmentSearch } from "../services/segment";
import { getApiCategoryList } from "../services/category";
import { getApiLanguageList } from "../services/language";
import { RouteComponentProps } from "react-router-dom"
import { Language } from "../models/Language"
import { Segment } from "../models/Segment"
import { Category } from "../models/Category"
import { useEffectAsync } from "../hooks/useData"
import { getApiVideoVideoid, postApiVideo, postApiVideoUpload, putApiVideoThumbnail, putApiVideoVideoid } from "../services/video"
import { getKeys } from "../utils/utilFunctions"
import { useHandleError } from "../hooks/useHandleError"
import ReactPlayer from "react-player"
import { MultipleSelectWithSearch } from "../components/MultipleSelect"
import { SingleSelect } from "../components/SingleSelect"
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Country } from "../models/Country"
import { postApiCountrySearch } from "../services/country"
import { postApiChannelSearch } from "../services/channel"
import { ChannelBase } from "../models/ChannelBase"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        margin: theme.spacing(3),
    },
    title: {
        padding: "10px",
        textAlign: 'center'
    },
    button: {
        backgroundColor: "#ff7a7a",
        padding: "14px 14px",
        color: "white",
        width: "100%",
        "&:hover": {
            backgroundColor: "#ff6363",
        },
    },
    cancelButton: {
        backgroundColor: "#919191",
        "&:hover": {
            backgroundColor: "#7a7a7a",
        },
    },
    videoPlayer: {
        margin: "20px",
    },
    feedback: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    videoBox: {
        margin: theme.spacing(4),
    },
    uploadButton: {
        marginBottom: "10px",
    }
}))

const Showcase = (p: { url: string }) => {
    return <ReactPlayer url={p.url} controls={true} />
}

const getLanguages = async () => (await getApiLanguageList()).items
const getSegments = async (channelId: number) => {
    if (!channelId) {
        return []
    }
    return (await postApiSegmentSearch({ channelId })).items;
}
const getCountries = async (channelId: number) => {
    if (!channelId) {
        return [];
    }
    return (await postApiCountrySearch({ channelId })).items;
}
const getCategories = async () => (await getApiCategoryList()).items
const getChannel = (categories: number) => async () => {
    if (categories) {
        return (await postApiChannelSearch({ categories: [categories] })).items;
    }
    return [];
}
function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const videoTypes = [
    { id: 0, name: 'Upload' },
    { id: 1, name: 'External' },
    { id: 2, name: 'YouTube' },
    { id: 3, name: 'YouTubeChannel' },
] as const;

export const VideoAdd = (p: RouteComponentProps<{ id: string }>) => {

    const styles = useStyles();
    const { routeTo } = useRouting();
    const [initialValues, setInitialValues] = useState<VideoForm>();
    const [videoFilePath, setVideoFilePath] = useState("");
    const [thumbnailPath, setThumbnailPath] = useState<string>(null!);
    const [isHidden, setIsHidden] = useState(true);

    type VideoForm = {
        title: string;
        type: 0 | 1 | 2 | 3;
        languages: Language[];
        segments: Segment[];
        category: Category;
        url?: string;
        externalId?: string;
        youtubeChannelId?: string;
        file: File | null;
        thumbnail?: File | null;
        channel: ChannelBase;
        countries: Country[];
        tumbnailUrl?: string;
        size?: number;
        fileType?: string;
    };

    const getInitialType = (id: number) => {
        return videoTypes.filter((type) =>
            type.id === id
        )
    }
    // const getChannelCategories = async (category: Category) => {
    //     const channelList = (await getApiChannelList()).items;
    //     return channelList.filter((c) => c.category!.id === category.id);
    // }

    useEffectAsync(async () => {
        if (p.match.params.id) {
            const video = await getApiVideoVideoid(+p.match.params.id);
            setVideoFilePath(video.url);
            setThumbnailPath(video.tumbnailUrl ?? "");
            setInitialValues({
                ...video,
                file: null,
                type: video.type,
                youtubeChannelId: video.youtubeChannelId,
                thumbnail: null!,

            });
        } else {
            setInitialValues({
                title: "",
                url: undefined,
                externalId: null!,
                type: null!,
                category: null!,
                segments: [],
                languages: [],
                file: null,
                thumbnail: null,
                youtubeChannelId: ""!,
                countries: [],
                channel: null!,
            });
        }
    }, []);
    const keys = getKeys<VideoForm>({
        title: 1,
        languages: 1,
        thumbnail: 1,
        category: 1,
        segments: 1,
        url: 1,
        externalId: 1,
        type: 1,
        file: 1,
        youtubeChannelId: 1,
        countries: 1,
        channel: 1,
        fileType: 1,
        size: 1,
        tumbnailUrl: 1,
    })

    const { onSubmit } = useHandleError(async (videoPayload: VideoForm) => {
        // setIsHidden(false);
        console.log(videoPayload);
        const file = videoPayload.file;
        const languages = videoPayload.languages.map(e => {
            if (typeof e !== 'number') {
                return e.id;
            }
            return e;
        });
        const segments = videoPayload.segments.map(segment => segment.id);
        const countries = videoPayload.countries.map(country => country.id);

        // const segmentId = 0//videoPayload.segments.id ?? 0;
        // const categoryId = videoPayload.category.id ?? 0;
        // const countryId = 0//videoPayload.country.id ?? 0;
        // const channelId = videoPayload.channel.id ?? 0;
        // const { type } = videoPayload;
        // const { externalId, youtubeChannelId } = videoPayload;
        let url = videoPayload.url;
        if (file) {
            url = (await postApiVideoUpload(file)).video;
        }
        let thumbnailUrl = videoPayload.tumbnailUrl;
        if (videoPayload.thumbnail) {
            thumbnailUrl = (await putApiVideoThumbnail(videoPayload.thumbnail)).picture;
        }
        let { title, externalId, type, youtubeChannelId } = videoPayload;
        youtubeChannelId = youtubeChannelId ? youtubeChannelId : null!;
        if (type === 3) {
            youtubeChannelId = externalId;
        }
        const video: VideoPayload = {
            title, externalId, type, youtubeChannelId,
            tumbnailUrl: thumbnailUrl,
            languages, segments, countries, url,
            categoryId: videoPayload.category.id,
            channelId: videoPayload.channel.id,
            fileType: videoPayload.fileType,
            size: videoPayload.size,
        };
        if (p.match.params.id) {
            await putApiVideoVideoid(+p.match.params.id, video);
        } else {
            await postApiVideo(video);
        }
        routeTo(urls.videoList);
    }, () => {
        setIsHidden(true);
    });

    if (!initialValues) {
        return <div></div>;
    }
    return <Container maxWidth="xl">
        <Paper variant="outlined" className={styles.root}>
            <Formik<VideoForm>
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values, setValues, handleChange, setFieldValue, isSubmitting, isValid }) => {
                    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
                        let fileType = event.currentTarget.files![0].name.split(".").pop();
                        let size = event.currentTarget.files![0].size
                        setValues({
                            ...values,
                            size,
                            fileType
                        })
                        setVideoFilePath(URL.createObjectURL(event.currentTarget.files![0]));
                        setFieldValue("file", event.currentTarget.files![0]);
                    }
                    const handleUploadThumbnail = (event: React.ChangeEvent<HTMLInputElement>) => {
                        setThumbnailPath(URL.createObjectURL(event.currentTarget.files![0]));
                        setFieldValue(keys.thumbnail, event.currentTarget.files![0]);

                    }
                    const uploadType = values.type === 0;
                    const externalType = values.type === 1;
                    const youtubeType = values.type === 2 || values.type === 3;
                    const canSave = values.type === null;
                    const channelSelect = values.category === null;
                    const handleTypeChange = (value: { name: string, id: number } | null) => {
                        if (value) {
                            setFieldValue("type", value.id);
                        } else {
                            setFieldValue("type", null);
                        }
                    }
                    return (
                        <Form>
                            <Backdrop className="loader" open={isSubmitting} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Typography variant="h4" gutterBottom className={styles.title}>
                                        {p.match.params.id ? 'Edit Video' : 'Add Video'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LinearProgress color="secondary" hidden={isHidden} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        className={styles.button}
                                        type="submit"
                                        variant="contained"
                                        disabled={!isHidden || canSave || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        onClick={e => {
                                            routeTo(urls.videoList);
                                        }}
                                        className={`${styles.button} ${styles.cancelButton}`}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        value={values.title}
                                        variant="outlined"
                                        label="Title"
                                        fullWidth
                                        id={keys.title}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SingleSelect
                                        label="Type"
                                        variant="outlined"
                                        value={getInitialType(initialValues.type)[0]}
                                        getSuggestions={() => videoTypes as any}
                                        disabled={p.match.params.id ? true : false}
                                        onChange={(value) => { handleTypeChange(value) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required={youtubeType}
                                        fullWidth
                                        label="External id"
                                        variant="outlined"
                                        value={values.externalId ?? ""}
                                        id={keys.externalId}
                                        onChange={handleChange}
                                        disabled={!youtubeType || (getInitialType(initialValues.type)[0]?.id === 3)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required={externalType}
                                        label="URL"
                                        variant="outlined"
                                        value={values.url}
                                        id={keys.url}
                                        onChange={handleChange}
                                        disabled={!externalType}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <MultipleSelectWithSearch
                                        label="Language"
                                        initialValues={initialValues.languages}
                                        getSuggestions={getLanguages}
                                        onChange={items => {
                                            setFieldValue("languages", items);
                                        }}
                                    // disabled={(initialValues.youtubeChannelId !== (undefined || null))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SingleSelect
                                        label="Category"
                                        variant="outlined"
                                        value={initialValues.category}
                                        getSuggestions={getCategories}
                                        onChange={(value) => {
                                            setValues({
                                                ...values,
                                                category: value!,
                                                channel: null!,
                                                segments: [],
                                                countries: [],
                                            })
                                        }}
                                    // disabled={(initialValues.youtubeChannelId !== (undefined || null))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SingleSelect
                                        label="Channel"
                                        variant="outlined"
                                        value={values.channel}
                                        getSuggestions={getChannel(values.category?.id)}
                                        onChange={(value) => { setFieldValue("channel", value) }}
                                        disabled={channelSelect}

                                        helperText={channelSelect ? "You must select a category" : ""}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MultipleSelectWithSearch
                                        label="Segments"
                                        textfieldProps={{
                                            helperText: !values.channel ? "You must select a channel" : "",
                                        }}
                                        disabled={!values.channel}
                                        Values={values.segments}
                                        initialValues={initialValues.segments}
                                        getSuggestions={() => getSegments(values.channel?.id)}
                                        onChange={(value) => {
                                            setFieldValue("segments", value)
                                        }}
                                    // disabled={(initialValues.youtubeChannelId !== (undefined || null))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MultipleSelectWithSearch
                                        label="Countries"
                                        textfieldProps={{
                                            helperText: !values.channel ? "You must select a channel" : "",
                                        }}
                                        Values={values.countries}
                                        disabled={!values.channel}
                                        initialValues={initialValues.countries}
                                        getSuggestions={() => getCountries(values.channel?.id)}
                                        onChange={(value) => {
                                            setFieldValue("countries", value)
                                        }}
                                    // disabled={(initialValues.youtubeChannelId !== (undefined || null))}
                                    />
                                </Grid>
                                <Grid item container xs={12}>
                                    {values.fileType && <Grid item xs={3} justify="center">
                                        <FormLabel>File Type: </FormLabel>
                                        {values.fileType ?? ""}
                                    </Grid>}
                                    {values.size && <Grid item xs={3}>

                                        <FormLabel>File Size: </FormLabel>
                                        {formatBytes(values.size ?? 0)}
                                    </Grid>}
                                </Grid>
                                <Grid item xs={8}>
                                    {/* <Box className={styles.videoBox} visibility={uploadType ? "visible" : 'hidden'}> */}
                                    <div style={{ visibility: uploadType ? "visible" : 'hidden' }}>
                                        <input
                                            value=""
                                            id="button-file"
                                            type="file"
                                            onChange={handleVideoUpload}
                                            style={{ display: "none" }}
                                        />
                                        <label htmlFor="button-file">
                                            <Button className={styles.uploadButton} variant="contained" component="span">
                                                File Upload <CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                            </Button>
                                        </label>
                                    </div>
                                    {/* </Box> */}
                                    {!(values.type === 3) &&
                                        <Showcase url={videoFilePath} />
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <div style={{ visibility: youtubeType ? "hidden" : "visible" }}>
                                            <input
                                                value=""
                                                id="thumbnail"
                                                type="file"
                                                onChange={handleUploadThumbnail}
                                                style={{ display: "none" }}
                                            />
                                            <label htmlFor="thumbnail">
                                                <Button className={styles.uploadButton}
                                                    variant="contained" component="span">
                                                    Upload Thumbnail<CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                                </Button>
                                            </label>
                                        </div>
                                        {thumbnailPath && <img height="auto" style={{ width: "100%", height: "100%" }}
                                            src={thumbnailPath} alt="img" />}
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Paper>
    </Container >
}