import { Container, Drawer, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { urls, useRouting } from "../../utils/routing";
import { SidebarNav, SidebarRouterProps } from "./sidebar-nav";
import PersonIcon from "@material-ui/icons/Person";
import VideoLibrarySharpIcon from "@material-ui/icons/VideoLibrarySharp";
import TranslateSharpIcon from "@material-ui/icons/TranslateSharp";
import GroupSharpIcon from "@material-ui/icons/GroupSharp";
import CategorySharpIcon from "@material-ui/icons/CategorySharp";
import FlagIcon from '@material-ui/icons/Flag';
import { CustomButton } from "../CustomButton";
import { localStorageUserKey } from "../../services/config";
import NotificationsIcon from '@material-ui/icons/Notifications';
import DvrIcon from '@material-ui/icons/Dvr';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#f2f2f2",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  drawerPaper: {
    overflowX: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    width: 180,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logoContainer: {
    position: "relative",
    marginTop: theme.spacing(3),
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  logout: {
    marginTop: "auto",
    marginBottom: "30px",
    color: 'white'
  },
}));

export type Props = any; //PropsWithChildren<{}>

export const Sidebar = (p: Props) => {
  const { routeTo } = useRouting();
  const [url] = useState<SidebarRouterProps[]>([
    {
      fn: urls.userList,
      itemText: "Users",
      icon: PersonIcon,
    },
    {
      fn: urls.categoryList,
      itemText: "Categories",
      icon: CategorySharpIcon,
    },
    {
      fn: urls.countryList,
      itemText: "Countries",
      icon: FlagIcon,
    },
    {
      fn: urls.channelList,
      itemText: "Channels",
      icon: DvrIcon,
    },
    {
      fn: urls.languageList,
      itemText: "Languages",
      icon: TranslateSharpIcon,
    },
    {
      fn: urls.segmentList,
      itemText: "Segments",
      icon: GroupSharpIcon,
    },
    {
      fn: urls.videoList,
      itemText: "Videos",
      icon: VideoLibrarySharpIcon,
    },
    {
      fn: urls.notificationList,
      itemText: "Notifications",
      icon: NotificationsIcon
    }
  ]);

  const [open] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem(localStorageUserKey);
    routeTo(urls.login);
  };

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Drawer
        variant="permanent"
        open={open}
        classes={{
          paper: styles.drawerPaper,
        }}
      >
        <div className={styles.logoWrapper}>
          <Container className={styles.logoContainer}>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
          </Container>
        </div>
        <SidebarNav url={url} />
        <CustomButton
          variant="text"
          color="secondary"
          className={styles.logout}
          onClick={handleLogout}
        >
          Log Out
        </CustomButton>
      </Drawer>
      <div className={styles.content}>
        <Container maxWidth={false} className={styles.container}>
          {p.children}
        </Container>
      </div>
    </div>
  );
};
