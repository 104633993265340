import { Language } from "../models/Language";
import MaterialTable, { Options } from "material-table";
import React, { forwardRef, useState } from "react";
import {
  deleteApiLanguageLanguageid,
  getApiLanguageList,
} from "../services/language";
import { useData } from "../hooks/useData";
import { AddCircle } from "@material-ui/icons";
import { urls, useRouting } from "../utils/routing";
import { Container } from "@material-ui/core";

export const LanguageList = () => {
  const { routeTo } = useRouting();
  const [reload, setReload] = useState(false);
  const { data, isLoading } = useData(getApiLanguageList, [reload]);
  const [pagination] = useState<Options<any>>({
    actionsColumnIndex: -1,
    sorting: false,
    pageSize: 10,
    toolbarButtonAlignment: "left",
  });
  if (isLoading) {
    return <div></div>;
  }
  return (
    <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
      <MaterialTable<Language>
        data={data!.items}
        title="Languages"
        options={pagination}
        icons={{
          Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
            <AddCircle {...props} ref={ref} />
          )),
        }}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Code",
            field: "code",
          },
        ]}
        actions={[
          {
            icon: () => <AddCircle />,
            tooltip: "Add New",
            isFreeAction: true,
            onClick: () => routeTo(urls.languageAdd),
          },
          {
            icon: "edit",
            tooltip: "Edit this entry",
            isFreeAction: false,
            onClick: (event, data) => {
              if (!Array.isArray(data)) {
                routeTo(urls.languageEdit, { id: data.id.toString() });
              }
            },
          },
        ]}
        editable={{
          onRowDelete: async (oldData) => {
            await deleteApiLanguageLanguageid(oldData.id);
            setReload(!reload);
          },
        }}
      />
    </Container>
  );
};
