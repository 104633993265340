import { Container, Grid } from "@material-ui/core";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import "./App.css";
import { Sidebar } from "./components/sidebar/sidebar";
import { useSavedUser } from "./hooks/useSavedUser";
import { CategoryList } from "./pages/categories-list";
import { ChannelList } from "./pages/channels-list";
import { CountryList } from "./pages/countries-list";
import { LanguageAdd } from "./pages/language-add";
import { LanguageList } from "./pages/language-list";
import { LoginPage } from "./pages/login";
import { SegmentList } from "./pages/segment-list";
import { UsersAdd } from "./pages/users-add";
import { UsersList } from "./pages/users-list";
import { VideoAdd } from "./pages/video-edit";
import { VideoList } from "./pages/video-list";
import { route, startUrl, urls } from "./utils/routing";
import { useTitle } from "./utils/utilFunctions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationsList } from "./pages/notifications-list";
import { ChannelAdd } from "./pages/channels-add";

function App() {
  useTitle("Rebel Cactus");

  const { userChecked } = useSavedUser();
  if (!userChecked) {
    return <div></div>;
  }
  return (
    <div>
      <Grid item container alignItems="stretch" direction="column">
        <Route path="/app">
          <Sidebar>
            <Container style={{ maxWidth: "100%", padding: "0 0 2rem", height: "93vh" }}>
              <Route exact path={route(urls.userList)} component={UsersList} />
              <Route exact path={route(urls.userAdd)} component={UsersAdd} />
              <Route
                exact
                path={route(urls.userEdit, ["id"])}
                component={UsersAdd}
              />
              <Route exact path={route(urls.videoList)} component={VideoList} />
              <Route exact path={route(urls.videoAdd)} component={VideoAdd} />
              <Route
                path={route(urls.channelEdit, ["id"])}
                component={ChannelAdd}
              />
              <Route
                path={route(urls.channelAdd)}
                component={ChannelAdd}
              />
              <Route
                exact
                path={route(urls.videoEdit, ["id"])}
                component={VideoAdd}
              />
              <Route
                exact
                path={route(urls.notificationList)}
                component={NotificationsList}
              />
              <Route
                exact
                path={route(urls.categoryList)}
                component={CategoryList}
              />
              <Route
                exact
                path={route(urls.countryList)}
                component={CountryList}
              />
              <Route
                exact
                path={route(urls.channelList)}
                component={ChannelList}
              />
              <Route
                exact
                path={route(urls.segmentList)}
                component={SegmentList}
              />
              <Route exact path={route(urls.languageList)} component={LanguageList} />
              <Route exact path={route(urls.languageAdd)} component={LanguageAdd} />
              <Route
                exact
                path={route(urls.languageEdit, ["id"])}
                component={LanguageAdd}
              />
              <Route exact path="/">
                <Redirect to={startUrl()} />
              </Route>
            </Container>
          </Sidebar>
        </Route>
        <ToastContainer />
        <Route exact path={route(urls.login)} component={LoginPage} />
      </Grid>
    </div>
  );
}

export default App;
