import { useState } from "react";
import { Form, Formik } from "formik";
import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { useEffectAsync } from "../hooks/useData";
import { getKeys } from "../utils/utilFunctions";

import { urls, useRouting } from "../utils/routing";
import { useHandleError } from "../hooks/useHandleError";
import FormFeedback from "../components/FormFeedback";
import { FormContainer } from "../components/FormContainer";
import { RouteComponentProps } from "react-router-dom";
import { ChannelPayload } from "../models/ChannelPayload";
import { getApiChannelChannelid, postApiChannel, putApiChannelChannelid } from "../services/channel";
import { Channel } from "../models/Channel";
import { SingleSelect } from "../components/SingleSelect";
import { MultipleSelectWithSearch } from "../components/MultipleSelect";
import { getApiSegmentList } from "../services/segment";
import { getApiCountryList } from "../services/country";
import { getApiCategoryList } from "../services/category";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    formGroup: {
        margin: "0 auto",
        maxWidth: "400px",
    },
    title: {
        paddingBottom: "10px",
        marginBottom: "25px",
        textAlign: 'center',
    },
    formItem: {
        marginBottom: "18px",
        "& .MuiOutlinedInput-input": {
            padding: "15px 15px",
            borderColor: "black",
        },
    },
    buttonGroup: {
        display: "flex",
        marginTop: "10px",
        marginBottom: "25px",
        justifyContent: "space-between",
    },
    button: {
        backgroundColor: "#ff7a7a",
        padding: "14px 14px",
        width: "50%",
        color: "white",
        "&:hover": {
            backgroundColor: "#ff6363",
        },
    },
    cancelButton: {
        backgroundColor: "#919191",
        marginLeft: "18px",
        "&:hover": {
            backgroundColor: "#7a7a7a",
        },
    },
}));
const getSegments = async () => {
    return (await getApiSegmentList()).items;
}
const getCountries = async () => {
    return (await getApiCountryList()).items;
}
const getCategories = async () => {
    return (await getApiCategoryList()).items;
}
export const ChannelAdd = (p: RouteComponentProps<{ id: string }>) => {
    const styles = useStyles();
    const { routeTo } = useRouting();

    const [initialValues, setInitialValues] = useState<Channel>(null!);

    useEffectAsync(async () => {
        if (p.match.params.id) {
            const channel = await getApiChannelChannelid(+p.match.params.id);
            setInitialValues({
                ...channel,
            });
        } else {
            setInitialValues({
                id: null!,
                countries: [],
                segments: [],
                name: "",
            });
        }
    }, []);
    const keys = getKeys<Channel>({
        category: 1,
        id: 1,
        countries: 1,
        name: 1,
        segments: 1
    });
    const { onSubmit, error } = useHandleError(async (obj: Channel) => {
        console.log(obj);
        const payload = {
            categoryId: obj.category!.id,
            countries: obj.countries.map(country => country.id),
            name: obj.name,
            segments: obj.segments.map(segments => segments.id),
        } as ChannelPayload;
        if (obj.id) {
            await putApiChannelChannelid(obj.id, payload);
        } else {
            await postApiChannel(payload);
        }
        routeTo(urls.channelList)
    });

    const handleCancel = () => {
        routeTo(urls.channelList);
    };
    if (!initialValues) {
        return <div></div>;
    }
    return (
        <FormContainer maxWidth={false} removeBox={true}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ values, handleChange, setValues }) => {
                    return (
                        <Form className={styles.formGroup}>
                            <Typography variant="h5" className={styles.title}>
                                {values.id ? `Edit ${initialValues.name}` : "Add Channel"}
                            </Typography>
                            <TextField
                                value={values.name}
                                className={styles.formItem}
                                variant="outlined"
                                label="Name"
                                required
                                fullWidth
                                onChange={handleChange}
                                id={keys.name}
                            />
                            <SingleSelect
                                value={values.category}
                                className={styles.formItem}
                                label="Category"
                                required
                                variant="outlined"
                                onChange={category => {
                                    setValues({ ...values, category: category! })
                                }}
                                getSuggestions={getCategories}
                            />
                            <div className={styles.formItem}>
                                <MultipleSelectWithSearch
                                    label="Segments"
                                    getSuggestions={getSegments}
                                    onChange={segments => {
                                        setValues({ ...values, segments })
                                    }}
                                    initialValues={values.segments}

                                />
                            </div>

                            <div className={styles.formItem}>
                                <MultipleSelectWithSearch
                                    label="Countries"
                                    getSuggestions={getCountries}
                                    onChange={countries => {
                                        setValues({ ...values, countries })
                                    }}
                                    initialValues={values.countries}

                                />
                            </div>
                            <div className={styles.buttonGroup}>
                                <Button
                                    className={styles.button}
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    className={`${styles.button} ${styles.cancelButton}`}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                            </div>
                            {error && <FormFeedback error={true}>{error}</FormFeedback>}
                        </Form>
                    );
                }}
            </Formik>
        </FormContainer>
    );
};
