import { Container } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import MaterialTable, { Options } from "material-table";
import React, { forwardRef, useState } from "react";
import { useData } from "../hooks/useData";
import { Segment } from "../models/Segment";
import {
  deleteApiSegmentSegmentid,
  getApiSegmentList,
  postApiSegment,
  putApiSegmentSegmentid,
} from "../services/segment";
export const SegmentList = () => {
  const [reload, setReload] = useState(false);
  const { data, isLoading } = useData(getApiSegmentList, [reload]);
  const [pagination,] = useState<Options<any>>({
    actionsColumnIndex: -1,
    sorting: false,
    pageSize: 10,
    toolbarButtonAlignment: "left",
});
  if (isLoading) {
    return <div></div>;
  }
  return (
    <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
      <MaterialTable<Segment>
        data={data?.items!}
        title="Segments"
        options={pagination}
        icons={{
          Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
            <AddCircle {...props} ref={ref} />
          )),
        }}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Minimum Age",
            field: "minAge",
          },
          {
            title: "Maximum Age",
            field: "maxAge",
          },
        ]}
        editable={{
          onRowAdd: async (newData) => {
            await postApiSegment(newData);
            setReload(!reload);
          },
          onRowUpdate: async (newData, oldData) => {
            const { id, ...newWithoudId } = newData;
            await putApiSegmentSegmentid(oldData!.id, newWithoudId);
            setReload(!reload);
          },
          onRowDelete: async (oldData) => {
            await deleteApiSegmentSegmentid(oldData.id);
            setReload(!reload);
          },
        }}
      />
    </Container>
  );
};
