import {
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIconProps,
  withStyles,
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import React, { useState } from "react";
import { useRouting } from "../../utils/routing";

const useStyles = makeStyles((theme) => ({
  drawerIcons: {
    color: "#86bb07",
  },
  item: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#ff8080",
      color: "white",
      "& .MuiListItemText-root,.MuiListItemIcon-root ": {
        color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#ff6666",
      color: "white",
      "& .MuiListItemText-root,.MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#ff6666",
      color: "white",
      "& .MuiListItemText-root, .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MuiListItem);

export type SidebarRouterProps = {
  fn: (p?: any) => string;
  itemText: string;
  icon: (props: SvgIconProps) => JSX.Element;
};

export const SidebarNav = (p: { url: SidebarRouterProps[] }) => {
  const { routeTo, history } = useRouting();
  const styles = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(
    p.url.map(e => e.fn().split("/")[2]).indexOf(history.location.pathname.split("/")[2])
  );
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List>
      {p.url.map((route, i) => (
        <div key={i}>
          <ListItem
            button
            selected={selectedIndex === i}
            className={styles.item}
            key={i + route.itemText}
            onClick={(e) => {
              handleListItemClick(e, i);
              routeTo(route.fn);
            }}
          >
            <ListItemIcon className={styles.drawerIcons}>
              <route.icon />
            </ListItemIcon>
            <ListItemText primary={route.itemText} />
          </ListItem>
        </div>
      ))}
    </List>
  );
};
