import { useState } from "react";

export function useHandleError<T extends object>(
    fn: (obj: T) => Promise<any>,
    onError?: () => void
) {
    const [error, setError] = useState<string>(null!);
    async function onSubmit(obj: T) {
        try {
            await fn(obj);
        } catch (e) {
            try {
                if (onError) {
                    onError();
                }
                const text = JSON.parse(e.text);
                setError(text.message);
                console.log(e.text);
            } catch (e) {
                setError(e.statusText);
            }
        }
    }
    return {
        error,
        onSubmit,
    };
}
