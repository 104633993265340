import { Button, LinearProgress, Grid, makeStyles, TextField, Typography } from "@material-ui/core"
import { Form, Formik } from "formik"
import React from "react"
import { useState } from "react"
import { urls, useRouting } from "../utils/routing"
import { RouteComponentProps } from "react-router-dom"
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useEffectAsync } from "../hooks/useData"
import { getApiLanguageLanguageid, postApiLanguage, putApiLanguageLanguageid, putApiLanguageLanguageidPicture } from "../services/language"
import { getKeys } from "../utils/utilFunctions"
import { useHandleError } from "../hooks/useHandleError"
import { LanguagePayload } from "../models/LanguagePayload"
import { FormContainer } from "../components/FormContainer"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        margin: theme.spacing(3),
    },
    title: {
        padding: "10px",
        textAlign: 'center'
    },
    formGroup: {
        margin: "0 auto",
        maxWidth: "700px",
    },
    imagePreview: {
        margin: "0 auto",
        paddingTop: "40px",
    },
    button: {
        backgroundColor: "#ff7a7a",
        padding: "14px 14px",
        color: "white",
        width: "100%",
        "&:hover": {
            backgroundColor: "#ff6363",
        },
    },
    cancelButton: {
        backgroundColor: "#919191",
        "&:hover": {
            backgroundColor: "#7a7a7a",
        },
    },
    videoPlayer: {
        margin: "20px",
    },
    feedback: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    videoBox: {
        margin: theme.spacing(4),
    }
}))

export const LanguageAdd = (p: RouteComponentProps<{ id: string }>) => {

    const styles = useStyles();
    const { routeTo } = useRouting();
    const [initialValues, setInitialValues] = useState<LanguageForm>();
    const [isHidden, setIsHidden] = useState(true);

    type LanguageForm = {
        code: string;
        name: string;
        file: File | null;
        pictureUrl?: string | undefined
    };

    useEffectAsync(async () => {
        if (p.match.params.id) {
            const language = await getApiLanguageLanguageid(+p.match.params.id);
            setInitialValues({
                ...language,
                file: null,
            });
        } else {
            setInitialValues({
                code: "",
                name: "",
                pictureUrl: "",
                file: null!,
            });
        }
    }, []);

    const keys = getKeys<LanguageForm>({
        code: 1,
        name: 1,
        pictureUrl: 1,
        file: 1,
    })

    const { onSubmit } = useHandleError(async (languagePayload: LanguageForm) => {
        setIsHidden(false);
        const { name, code, file } = languagePayload;
        const language: LanguagePayload = { name, code }
        if (p.match.params.id) {
            await putApiLanguageLanguageid(+p.match.params.id, language);
            await putApiLanguageLanguageidPicture(+p.match.params.id, file!)
        } else {
            const lang = await postApiLanguage(language);
            if (file) {
                await putApiLanguageLanguageidPicture(lang.id, file);
            }
        }
        routeTo(urls.languageList);
    }, () => {
        setIsHidden(true);
    });

    if (!initialValues) {
        return <div></div>;
    }
    return (
        <FormContainer maxWidth="xl" removeBox={true}>
            <Formik<LanguageForm>
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setValues }) => {

                    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.files) {
                            const file = event.target.files[0];
                            const picture = URL.createObjectURL(file);
                            setValues({ ...values, pictureUrl: picture, file: file })
                        }
                    }

                    return (
                        <Form className={styles.formGroup}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Typography variant="h4" gutterBottom className={styles.title}>
                                        {p.match.params.id ? 'Edit Language' : 'Add Language'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LinearProgress color="secondary" hidden={isHidden} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        className={styles.button}
                                        type="submit"
                                        variant="contained"
                                        disabled={!isHidden}
                                    >
                                        Save
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        onClick={e => {
                                            routeTo(urls.languageList);
                                        }}
                                        className={`${styles.button} ${styles.cancelButton}`}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={keys.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        label="Code"
                                        variant="outlined"
                                        value={values.code}
                                        id={keys.code}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <input
                                            id="button-file"
                                            type="file"
                                            onChange={handleImageUpload}
                                            style={{ display: "none" }}
                                        />
                                        <label htmlFor="button-file">
                                            <Button variant="contained" component="span">
                                                File Upload <CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                <div className={styles.imagePreview} >
                                    <img alt="" src={values.pictureUrl} width="400px" height="auto" />
                                </div>

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </FormContainer>
    );
}