import { Container } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import MaterialTable, { Options } from "material-table"
import { forwardRef, useState } from "react"
import { useData } from "../hooks/useData";
import { Channel } from "../models/Channel";
import { getApiCategoryList } from "../services/category";
import { deleteApiChannelChannelid, getApiChannelList } from "../services/channel";
import { urls, useRouting } from "../utils/routing";
import { getCustomFilterAndSearch } from "../utils/utilFunctions";

export const ChannelList = () => {
  const [reload, setReload] = useState(false);
  const { routeTo } = useRouting();
  const { data, isLoading } = useData(getApiChannelList, [reload]);
  const [pagination,] = useState<Options<any>>({
    actionsColumnIndex: -1,
    sorting: false,
    pageSize: 10,
    toolbarButtonAlignment: "left",
  });

  const { data: categories } = useData(getApiCategoryList, [reload]);

  if (isLoading || !categories) {
    return <div></div>;
  }
  return (
    <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
      <MaterialTable<Channel>
        data={data?.items!}
        title="Channels"
        options={pagination}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Category",
            field: "category",
            render: rowData => categories.items.find((category) => category.id === rowData.category!.id)?.name,
            ...getCustomFilterAndSearch<Channel>(obj => categories.items.find((category) => category.id === obj.category!.id)?.name ?? "")
          },
          {
            title: "Segments",
            field: "segment",
            render: rowData => rowData.segments.map(item => item.name).toString(),
            ...getCustomFilterAndSearch<Channel>(obj => obj.segments.map(item => item.name).toString(),)
          },
          {
            title: "Countries",
            field: "countries",
            render: rowData => rowData.countries.map(item => item.name).toString(),
            ...getCustomFilterAndSearch<Channel>(obj => obj.countries.map(item => item.name).toString(),)
          }
        ]}
        icons={{
          Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
            <AddCircle {...props} ref={ref} />
          )),
        }}
        actions={[{
          icon: () => <AddCircle />,
          tooltip: "Add New",
          isFreeAction: true,
          onClick: () => routeTo(urls.channelAdd)
        }, {
          icon: "edit",
          tooltip: "Edit",
          isFreeAction: false,
          onClick: (e, rowData) => routeTo(urls.channelEdit, { id: (rowData as any).id })
        }, {
          icon: "delete",
          tooltip: "delete",
          isFreeAction: false,
          onClick: async (e, rowData) => {
            const confirmation = window.confirm("Are you sure you want to delete the selected items?");
            if (confirmation) {
              await deleteApiChannelChannelid((rowData as any).id)
              setReload(!reload);
            }
          }
        }]}
      />
    </Container>
  );
};