import { Container } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import MaterialTable, { Options } from "material-table"
import React, { forwardRef, useState } from "react"
import { useData } from "../hooks/useData";
import { Country } from "../models/Country"
import { deleteApiCountryCountryid, getApiCountryList, postApiCountry, putApiCountryCountryid } from "../services/country";

export const CountryList = () => {
    const [reload, setReload] = useState(false);
    const { data, isLoading } = useData(getApiCountryList, [reload]);
    const [pagination,] = useState<Options<any>>({
        actionsColumnIndex: -1,
        sorting: false,
        pageSize: 10,
        toolbarButtonAlignment: "left",
    });
    if (isLoading) {
        return <div></div>;
    }
    return (
        <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
            <MaterialTable<Country>
                data={data?.items!}
                title="Countries"
                options={pagination}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                    },
                ]}
                icons={{
                    Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
                        <AddCircle {...props} ref={ref} />
                    )),
                }}
                editable={{
                    onRowAdd: async (newData) => {
                        await postApiCountry(newData);
                        setReload(!reload);
                    },
                    onRowUpdate: async (newData, oldData) => {
                        const { id, ...newWithoudId } = newData;
                        await putApiCountryCountryid(oldData!.id, newWithoudId);
                        setReload(!reload);
                    },
                    onRowDelete: async (oldData) => {
                        await deleteApiCountryCountryid(oldData.id);
                        setReload(!reload);
                    },
                }}
            />
        </Container>
    );
};