export const localStorageUserKey = "user";
export const baseUrl = process.env.REACT_APP_BASE_URL ?? "http://31.14.16.236:8008/";
// swagger: http://31.14.16.236:8085/swagger-ui.html#/
export const headers: {
    Authorization: string
    'Accept': string;
    'Content-Type': string;
} = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    Authorization: "",
};