import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { Language } from "../models/Language";
import { useEffectAsync } from "../hooks/useData";
import { getKeys } from "../utils/utilFunctions";

import {
  getApiUserUserid,
  postApiUser,
  putApiUserUserid,
} from "../services/user";
import { urls, useRouting } from "../utils/routing";
import { useHandleError } from "../hooks/useHandleError";
import FormFeedback from "../components/FormFeedback";
import { FormContainer } from "../components/FormContainer";
import { RouteComponentProps } from "react-router-dom";
import { NewUserPayload } from "../models/NewUserPayload";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formGroup: {
    margin: "0 auto",
    maxWidth: "400px",
  },
  title: {
    paddingBottom: "10px",
    marginBottom: "25px",
    textAlign: 'center',
  },
  formItem: {
    marginBottom: "18px",
    "& .MuiOutlinedInput-input": {
      padding: "15px 15px",
      borderColor: "black",
    },
  },
  buttonGroup: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "25px",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#ff7a7a",
    padding: "14px 14px",
    width: "50%",
    color: "white",
    "&:hover": {
      backgroundColor: "#ff6363",
    },
  },
  cancelButton: {
    backgroundColor: "#919191",
    marginLeft: "18px",
    "&:hover": {
      backgroundColor: "#7a7a7a",
    },
  },
}));

export const UsersAdd = (p: RouteComponentProps<{ id: string }>) => {
  const styles = useStyles();
  const { routeTo } = useRouting();

  type UserForm = {
    language?: Language;
    id: number;
    email?: string;
    password: string;
    age?: number;
    languageId: number;
    isAdmin: boolean;
    uid?: string;
    authType: 0 | 1;
  };
  const [initialValues, setInitialValues] = useState<UserForm>(null!);

  useEffectAsync(async () => {
    if (p.match.params.id) {
      const user = await getApiUserUserid(+p.match.params.id);
      setInitialValues({
        ...user,
        password: "",
        languageId: user.language ? user.language.id : 0,
      });
    } else {
      setInitialValues({
        email: "",
        password: "",
        age: 0,
        languageId: 0,
        id: null!,
        language: null!,
        authType: 0 as const,
        isAdmin: true,
        uid: null as any,
      });
    }
  }, []);
  const keys = getKeys<UserForm>({
    email: 1,
    password: 1,
    age: 1,
    languageId: 1,
    id: 1,
    language: 1,
    authType: 1,
    isAdmin: 1,
    uid: 1,
  });
  const { onSubmit, error } = useHandleError(async (obj: UserForm) => {
    if (obj.id) {
      const { id, language, password, ...rest } = obj;
      await putApiUserUserid(id, rest);
    } else {
      const { id, language, ...submitValue } = obj;
      const newUser: NewUserPayload = { ...submitValue, languageId: 1 }
      await postApiUser(newUser);
    }
    routeTo(urls.userList);
  });

  const handleCancel = () => {
    routeTo(urls.userList);
  };
  if (!initialValues) {
    return <div></div>;
  }
  return (
    <FormContainer maxWidth={false} removeBox={true}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange }) => {
          return (
            <Form className={styles.formGroup}>
              <Typography variant="h5" className={styles.title}>
                {values.id ? `Edit ${initialValues.email}` : "Add User"}
              </Typography>
              <TextField
                value={values.email}
                className={styles.formItem}
                variant="outlined"
                type="email"
                label="Email"
                required
                fullWidth
                onChange={handleChange}
                id={keys.email}
              />

              <TextField
                disabled={!!values.id}
                value={values.password ?? ""}
                className={styles.formItem}
                variant="outlined"
                label="Password"
                fullWidth
                required
                type="password"
                onChange={handleChange}
                id={keys.password}
              />
              <div className={styles.buttonGroup}>
                <Button
                  className={styles.button}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  className={`${styles.button} ${styles.cancelButton}`}
                  type="submit"
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
              {error && <FormFeedback error={true}>{error}</FormFeedback>}
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
