import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 6),
    },
  },
});
const useStyles = makeStyles(styles);
export const FormContainer: React.FunctionComponent<{
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  removeBox?: boolean;
}> = (props) => {
  const { children, maxWidth, removeBox } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth={maxWidth ?? "sm"}>
        {!removeBox ? <Box mt={7} mb={12}>
          <Paper className={classes.paper}>{children}</Paper>
        </Box> : <Paper className={classes.paper}>{children}</Paper>}
      </Container>
    </div>
  );
};
