import React, { forwardRef, useState } from "react";
import MaterialTable, { Options } from "material-table";
import { Category } from "../models/Category";
import { useData } from "../hooks/useData";
import {
  deleteApiCategoryCategoryid,
  getApiCategoryList,
  postApiCategory,
  putApiCategoryCategoryid,
} from "../services/category";
import { AddCircle } from "@material-ui/icons";
import { Container } from "@material-ui/core";

export const CategoryList = () => {
  const [reload, setReload] = useState(false);
  const { data, isLoading } = useData(getApiCategoryList, [reload]);
  const [pagination,] = useState<Options<any>>({
    actionsColumnIndex: -1,
    sorting: false,
    pageSize: 10,
    toolbarButtonAlignment: "left",
  });
  if (isLoading) {
    return <div></div>;
  }
  return (
    <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
      <MaterialTable<Category>
        data={data?.items!}
        title="Categories"
        options={pagination}
        columns={[
          {
            title: "Name",
            field: "name",
          },
        ]}
        icons={{
          Add: forwardRef<SVGSVGElement, {}>((props, ref) => (
            <AddCircle {...props} ref={ref} />
          )),
        }}
        editable={{
          onRowAdd: async (newData) => {
            await postApiCategory(newData);
            setReload(!reload);
          },
          onRowUpdate: async (newData, oldData) => {
            const { id, ...newWithoudId } = newData;
            await putApiCategoryCategoryid(oldData!.id, newWithoudId);
            setReload(!reload);
          },
          onRowDelete: async (oldData) => {
            await deleteApiCategoryCategoryid(oldData.id);
            setReload(!reload);
          },
        }}
      />
    </Container>
  );
};
