import { Videos } from "../models/Videos";
import { Video } from "../models/Video";
import { VideoPayload } from "../models/VideoPayload";
import { Model12 } from "../models/Model12";
import { PictureResponse } from "../models/PictureResponse";
import { baseUrl, headers } from "./config";
import { appendFormData, fetchAndParse } from "./utils";
export function getApiVideoList(): Promise<Videos> {
    const url = new URL(`/api/video/list`, baseUrl);
    return fetchAndParse<Videos>(url.toString(), { method: "GET", headers });
}
export function getApiVideoVideoid(videoId: number): Promise<Video> {
    const url = new URL(`/api/video/${videoId}`, baseUrl);
    return fetchAndParse<Video>(url.toString(), { method: "GET", headers });
}
export function putApiVideoVideoid(videoId: number, body?: VideoPayload): Promise<Video> {
    const url = new URL(`/api/video/${videoId}`, baseUrl);
    return fetchAndParse<Video>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiVideoVideoid(videoId: number): Promise<boolean> {
    const url = new URL(`/api/video/${videoId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiVideo(body?: VideoPayload): Promise<Video> {
    const url = new URL(`/api/video`, baseUrl);
    return fetchAndParse<Video>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiVideoSearch(body?: Model12): Promise<Videos> {
    const url = new URL(`/api/video/search`, baseUrl);
    return fetchAndParse<Videos>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiVideoUpload(file: File): Promise<{
    video: string;
}> {
    const localHeaders = { ...headers, 'Accept': '*/*' };
    delete (localHeaders as any)["Content-Type"];
    const data = new FormData();
    data.append("file", file)
    const url = new URL(`/api/video/upload`, baseUrl);
    return fetchAndParse<{
        video: string;
    }>(url.toString(), { method: "POST", body: data, headers: localHeaders });
}
export function putApiVideoThumbnail(picture: File): Promise<PictureResponse> {
    const localHeaders = { ...headers };
    delete (localHeaders as any)["Content-Type"];
    const data = new FormData();
    appendFormData(data, "picture", picture);
    const url = new URL(`/api/video/thumbnail`, baseUrl);
    return fetchAndParse<PictureResponse>(url.toString(), { method: "PUT", body: data, headers: localHeaders });
}
export function putApiVideoFavoriteVideoid(videoId: number): Promise<string> {
    const url = new URL(`/api/video/favorite/${videoId}`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "PUT", body: null, headers });
}
export function deleteApiVideoFavoriteVideoid(videoId: number): Promise<boolean> {
    const url = new URL(`/api/video/favorite/${videoId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
