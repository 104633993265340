import { useHistory } from "react-router-dom";

export const urls = {
    login: () => "/login",

    videoList: () => "/app/video/list",
    videoAdd: () => "/app/video/add",
    videoEdit: (p: { id: number }) => `/app/video/edit/${p.id}`,

    categoryList: () => "/app/category/list",
    countryList: () => "/app/country/list",

    channelList: () => "/app/channel/list",
    channelAdd: () => "/app/channel/add",
    channelEdit: (p: { id: string }) => `/app/channel/edit/${p.id}`,

    segmentList: () => "/app/segment/list",
    languageList: () => "/app/language/list",
    languageAdd: () => "/app/language/add",
    languageEdit: (p: { id: string }) => `/app/language/edit/${p.id}`,

    userList: () => "/app/user/list",
    userAdd: () => "/app/user/add",
    userEdit: (p: { id: string }) => `/app/user/edit/${p.id}`,

    notificationList: () => "/app/notification/list",
};

export const startUrl = urls.login;

export function useRouting() {
    const history = useHistory();

    function routeTo(fn: () => string): void;
    function routeTo<T>(fn: (p: T) => string, params: T): void;
    function routeTo<T>(fn: (p?: T) => string, params?: T) {
        history.push(fn(params));
    }
    return {
        routeTo,
        history,
    };
}
export function route(fn: () => string): string;
export function route<T>(fn: (p: T) => string, params: Array<keyof T>): string;
export function route<T>(fn: (p: T) => string, params: Array<keyof T> = []) {
    const parameter = Object.fromEntries(params.map((p) => [p, ":" + p]));
    return fn(parameter as any);
}
