import React, { useRef, useState } from "react"
import MaterialTable, { Options } from "material-table"
import { urls, useRouting } from "../utils/routing"
import { IconButton, Icon, Container } from "@material-ui/core";
import { deleteApiVideoVideoid, getApiVideoList } from "../services/video";
import { useData } from "../hooks/useData";
import { videoTypes } from "./video-edit";
import { AddCircle } from "@material-ui/icons";
import { Video } from "../models/Video";
import { getCustomFilterAndSearch } from "../utils/utilFunctions";

export const VideoList = () => {

    const [reload, setReload] = useState(false);
    const { data, isLoading } = useData(getApiVideoList, [reload]);
    const [pagination,] = useState<Options<any>>({
        actionsColumnIndex: -1,
        sorting: false,
        pageSize: 10,
        toolbarButtonAlignment: "left",
    });
    const { routeTo } = useRouting();
    const ref = useRef<any>();

    if (isLoading) {
        return <div></div>;
    }
    return <Container maxWidth="xl" className="table-container table-list-pagination-bottom">
        <MaterialTable<Video>
            title="Videos"
            tableRef={ref}
            options={pagination}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "5%" } },
                {
                    title: "Channel", field: "channel", render: rowData => <div>{rowData.channel.name}</div>, cellStyle: { width: "20%" },
                    ...getCustomFilterAndSearch<Video>(obj => obj.channel.name)
                },
                { title: "Title", field: "title" },
                {
                    title: "Type", render: (e) => videoTypes[e.type].name, cellStyle: { width: "10%" },
                    ...getCustomFilterAndSearch<Video>(obj => videoTypes[obj.type].name)
                },
                { title: "Url", field: "url", cellStyle: { width: "40%" } },
                { title: "Category", field: "category.name", cellStyle: { width: "20%" } },
                { title: "Youtube Channel Id", field: "youtubeChannelId", cellStyle: { width: "20%" } },
                { title: "Segment", field: "segment.name", cellStyle: { width: "10%" } },
                {
                    title: "Countries", field: "country", render: rowData => <div>{rowData.countries.map(c => c.name).toString()}</div>,

                    ...getCustomFilterAndSearch<Video>(obj => obj.countries.map(c => c.name).toString())
                },
                {
                    title: "Actions",
                    render: (e) => (<div>
                        <IconButton onClick={() => routeTo(urls.videoEdit, { id: e.id! })} style={{ float: "right" }}><Icon>edit</Icon></IconButton>
                        <IconButton onClick={async () => {
                            const confirmation = window.confirm("Are you sure you want to delete the selected items?");
                            if (confirmation) {
                                await deleteApiVideoVideoid(e.id)
                            }
                            setReload(!reload);
                            if (ref.current) {
                                ref.current.onQueryChange();
                            }
                        }}><Icon>delete</Icon></IconButton>
                    </div>)
                }
            ]}
            data={data?.items!}
            actions={[{
                icon: () => <AddCircle />,
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => routeTo(urls.videoAdd)
            }
            ]}
        >
        </MaterialTable>
    </Container>
}