import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLoadData } from '../utils/utilFunctions';
import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';

type Props<T> = {
    required?: boolean;
    label: string;
    disabled?: boolean;
    initialValues?: T[];
    onChange: (values: T[]) => any;
    noOptionsText?: string;
    Values?: T[],
    renderOption?: (value: T) => string;
    textfieldProps?: TextFieldProps;
}
/**
 * 
 * @param props You can provide direct array to the value prop
 *          or an async function with the array as a return 
 *          value to the getSuggestions prop
 * @warning You can only provide values prop OR getSuggestion prop,
 *          cannot use both, this will signal an error 
 */
export function MultipleSelectWithSearch<T extends { name: string; id: number }>(props: Props<T> & {
    getSuggestions: () => Promise<T[]>;
}): JSX.Element
export function MultipleSelectWithSearch<T extends { name: string; id: number }>(props: Props<T> & {
    values: T[];
}): JSX.Element
export function MultipleSelectWithSearch<T extends { name: string; id: number }>(props: Props<T> & {
    getSuggestions?: () => Promise<T[]>;
    values?: T[];
}) {
    const { initialValues, label, disabled, textfieldProps, Values } = props;
    const [value, setValue] = React.useState<T[]>(initialValues ?? []);
    const [values, setValues] = React.useState<T[]>([]);
    const loading = !values;

    useLoadData(async () => {
        if (props.values) {
            setValues(props.values);
        }
        if (props.getSuggestions) {
            const data = await props.getSuggestions();
            setValues(data);
        }
    }, [props.getSuggestions, props.values]);

    useEffect(() => {
        setValue(Values ?? []);
    }, [Values]);
    // this use efect is used to reset values if the same render is used
    useEffect(() => {
        setValue(initialValues ?? []);
        // eslint-disable-next-line
    }, [label]);

    return (
        <Autocomplete
            multiple
            id="multiple-select-component"
            disabled={disabled}
            options={values}
            getOptionLabel={(option) => option.name}
            value={value}
            disableClearable
            aria-required={true}
            noOptionsText={props.noOptionsText ?? "No options"}
            getOptionSelected={(option, value) => {
                return option.id === value.id || +option.id === value.id;
            }}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onChange(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...textfieldProps}
                    fullWidth
                    required={props.required ?? false}
                    label={label}
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
